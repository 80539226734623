import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

const useResendOtp = (userName) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);

  const handleResend = async () => {
    const reqData = new FormData();
    reqData.append("username", userName);
    reqData.append("mode", "login");
    setLoading(true);
    try {
      const { data, status } = await axiosInstance.post(
        "api/resend-otp",
        reqData
      );

      if (status === 200) {
        enqueueSnackbar(data.message);
        setTimer(60);
        setLoading(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  return { handleResend, timer, loading };
};

export default useResendOtp;
