import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import Ternary from "src/components/ternary";
import axiosInstance from "src/utils/axios";
import Transition from "src/utils/dialog-animation";
import useTwoFactorAuthentication from "./hooks/use-2fa-login";

const EnterOtp = ({ data }) => {
  const { secret, email } = data;
  const { methods, onSubmit } = useTwoFactorAuthentication(data);
  const open = Boolean(secret);
  const { enqueueSnackbar } = useSnackbar();
  const {
    formState: { isSubmitting },
  } = methods;
  const [otpSend, setOtpSend] = useState(false);
  const [sending, setSending] = useState(false);
  const recover = async () => {
    setSending(true);
    if (sending) return;
    try {
      const reqData = new FormData();
      reqData.append("email", email);
      const { data } = await axiosInstance.post(
        "api/twofa-recovery-mail",
        reqData
      );
      setOtpSend(true);
      enqueueSnackbar(data.message);
    } catch (err) {
      setOtpSend(false);
      setSending(false);
      console.log(err);
    }
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} fullWidth>
      <DialogTitle>Two-Factor Authentication (2FA)</DialogTitle>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText>
            Two-Factor Authentication is enabled for your account. Please enter
            the OTP found in the authenticator app you used to set up 2FA.
          </DialogContentText>
          <Stack mt={2} spacing={3}>
            <RHFTextField
              autoFocus
              label="Enter OTP"
              type="number"
              name="code"
            />
            <Box sx={{ width: "100%", textAlign: "right" }}>
              <Typography
                fontWeight="bold"
                color="primary.main"
                variant="caption"
                onClick={recover}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                <Ternary
                  when={otpSend}
                  then="OTP send to registered email"
                  otherwise="Recover OTP"
                />
              </Typography>
            </Box>
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              verify
            </LoadingButton>
          </Stack>
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
};

export default EnterOtp;
