import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { RHFTextField } from "src/components/hook-form";
import Transition from "src/utils/dialog-animation";
import { useFormContext } from "react-hook-form";
import ErrorBanner from "../error-banner";
import useResendOtp from "./use-resend-otp";
import Translate from "src/components/translate";

const EnterOtp = ({ open, onSubmit }) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = useFormContext();
  const userName = watch("username");
  const isOtp = watch("otp");
  const {
    handleResend,
    timer,
    loading: resendLoading,
  } = useResendOtp(userName);

  return (
    <Dialog open={open} TransitionComponent={Transition} fullWidth>
      <DialogTitle>
        <Translate>global.verify_otp</Translate>
      </DialogTitle>
      <DialogContent>
        <DialogContentText mt={1}>
          <Translate>global.verify_subtitle</Translate>
        </DialogContentText>
        <Stack mt={4} spacing={3} mb={4}>
          <ErrorBanner />

          <RHFTextField
            autoFocus
            label="global.enter_otp"
            type="number"
            name="otp"
          />
          <Box sx={{ width: "100%", textAlign: "right" }}>
            <LoadingButton
              loading={resendLoading}
              disabled={timer > 0 ? true : false}
              onClick={timer === 0 ? handleResend : null}
            >
              <Translate>global.resend_otp</Translate>
              {timer > 0 && (
                <span style={{ width: "60px" }}>{`0:${timer} sec`}</span>
              )}
            </LoadingButton>
          </Box>
          <LoadingButton
            loading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            size="large"
            disabled={!isOtp}
          >
            <Translate>global.verify</Translate>
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default EnterOtp;
